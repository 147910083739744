<template>
  <footer
    class="bg-slate-800 lg:rounded text-center text-gray-400 py-4 text-xs"
  >
    &copy; 2021 <strong>Kespettacolo</strong>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
