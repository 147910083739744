<template>
  <header
    class="navbar flex items-center justify-between lg:rounded bg-slate-800 p-4 gap-2 sticky top-0 xl:top-4 transition leading-0"
  >
    <button @click="back">
      <IconArrowLeft
        v-show="routeName !== 'Sessions'"
        class="w-5 h-5 opacity-70 hover:opacity-100 transition"
      />
    </button>

    <div class="flex items-center gap-8 text-sm text-slate-400">
      <div class="hidden lg:flex gap-1 flex-end">
        Benvenuto, <strong>{{ $store.state.email }}</strong>
      </div>

      <button
        @click="logout"
        class="flex gap-2 border border-opacity-50 text-opacity-70 px-4 py-2 rounded font-bold hover:bg-white hover:text-gray-900 transition"
      >
        Esci <IconLogout class="w-5 h-5" />
      </button>
    </div>
  </header>
</template>

<script>
import IconMenu from "@/components/icons/IconMenu.vue";
import IconArrowLeft from "@/components/icons/IconArrowLeft.vue";
import IconLogout from "@/components/icons/IconLogout.vue";
import { getClient } from "@/includes/api";

export default {
  name: "Navbar",
  components: { IconMenu, IconArrowLeft, IconLogout },
  props: ["showSidebar"],
  data() {
    return {
      routeName: null,
    };
  },
  methods: {
    back() {
      return this.$router.go(-1);
    },
    toggleSidebar() {
      this.$props.showSidebar = !this.showSidebar;
    },
    async logout() {
      const apiClient = getClient();
      apiClient.setToken(null);
      this.$router.push({ name: "Home" });
    },
  },
  created() {
    this.routeName = this.$route.name;
  },
};
</script>

<style></style>
