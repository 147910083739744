<template>
  <div>
    <Navbar />

    <div class="px-4 lg:px-2 py-8 2xl:py-10 md:px-12 flex-1 container mx-auto">
      <div class="flex justify-between items-center mb-4">
        <h1 class="text-2xl 2xl:text-4xl font-semibold mb-4">Privacy Policy</h1>

        <button
          @click.prevent="isEditing = !isEditing"
          type="submit"
          class="btn btn--red self-start"
        >
          Modifica
        </button>
      </div>

      <div
        v-if="message"
        class="mt-2 p-1 border rounded-sm text-xs bg-green-100 border-green-200 text-green-500 mb-4"
      >
        {{ message }}
      </div>

      <form class="flex flex-col gap-4 mb-6">
        <textarea
          name="value"
          id="value"
          class="bg-transparent border-none rounded w-full min-h-screen h-auto text-white placeholder-white p-0"
          :class="{ 'bg-white text-gray-900 p-2 h-96': isEditing }"
          v-model="value"
          :disabled="!isEditing"
        ></textarea>

        <button
          v-show="isEditing"
          @click.prevent="editLabel"
          type="submit"
          class="btn btn--red self-start"
        >
          Salva
        </button>
      </form>
    </div>

    <Footer />
  </div>
</template>

<script>
import { getClient } from "@/includes/api";
import ApiClient from "@/includes/api";

import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: { Navbar, Footer },
  data() {
    return {
      privacyText: "",
      key: null,
      value: null,
      isEditing: false,
      message: "",
    };
  },
  async mounted() {
    try {
      this.isEditing = true;
      const apiClient = getClient();
      let labels = await apiClient.getLabels();
      let privacy = labels.filter((label) => label.key === "privacy_policy");
      console.log(privacy);
      this.value = privacy[0].value;
      this.isEditing = false;
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    async editLabel() {
      try {
        let key = "privacy_policy";
        let value = this.value;
        let apiClient = getClient();
        let labelEdited = await apiClient.editLabel(key, value);
        this.privacyText = value;
        this.isEditing = false;
        this.message = "Privacy Policy modificata correttamente!";

        setTimeout(() => {
          this.message = "";
        }, 5000);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style></style>
